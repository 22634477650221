<template>
  <div class="h-full w-full">
    <div
      v-if="hasError"
      class="min-h-full min-w-full flex bg-gray-500"
    >
      <div class="flex mx-auto my-auto text-red-400 flex-row">
        <p class="font-normal text-2xl">
          <span class="font-extrabold text-6xl">ERROR</span>
          Please log out and back in
        </p>
      </div>
    </div>
    <div
      v-else
      class="h-full flex flex-col px-0 pt-2 pr-2 pb-4"
    >
      <div class="bg-blue-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="flex my-auto mx-1">
          AUTH_LOG
          <HashLoader
            :size="20"
            v-show="loadingA"
            class="px-4"
            :color="`#FFFFFF`"
          />
        </div>
        <div class="flex flex-row">
          <div class="flex">
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm items-center"
              v-on:click.prevent="SET_AUTH_HEIGHT()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                />
              </svg>
            </button>
          </div>
          <div class="flex">
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm"
              v-on:click.prevent="switchAutoUpdate('AUTH_LOG')"
            >
              {{
                !enableAutoUpdateAuthLogs
                  ? "ENABLE:AUTO_UPDATE"
                  : "DISABLE:AUTO_UPDATE"
              }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex-col px-0 py-1 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
        v-bind:class="GET_AUTH_HEIGHT()"
      >
        <div
          v-for="authlog in AUTH_LOGS"
          :key="authlog.id"
          class="text-xs px-1 leading-5"
        >
          <p class="border-b border-dashed font-mono">
            "{{
              new Date(authlog.created_at)
                .toLocaleTimeString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit"
                })
                .split(", ")
                .join(" ")
            }}" {{ authlog.event_type }} "{{ authlog.event_message }}"
            {{ authlog.login }} {{ authlog.event_source }}
          </p>
        </div>
      </div>
      <div class="bg-blue-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="flex my-auto mx-1">
          SYSTEM_LOG
          <HashLoader
            :size="20"
            v-show="loadingS"
            class="px-4"
            :color="`#FFFFFF`"
          />
        </div>
        <div class="flex flex-row">
          <div class="flex">
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm items-center"
              v-on:click.prevent="SET_SYSTEM_HEIGHT()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                />
              </svg>
            </button>
          </div>
          <div class="flex">
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm"
              v-on:click.prevent="switchAutoUpdate('SYSTEM_LOG')"
            >
              {{
                !enableAutoUpdateSystemLogs
                  ? "ENABLE:AUTO_UPDATE"
                  : "DISABLE:AUTO_UPDATE"
              }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex-col px-0 py-1 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
        v-bind:class="GET_SYSTEM_HEIGHT()"
      >
        <div
          v-for="systemlog in SYSTEM_LOGS"
          :key="systemlog.id"
          class="text-xs px-1 leading-5"
        >
          <p class="border-b border-dashed font-mono">
            "{{
              new Date(systemlog.created_at)
                .toLocaleTimeString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit"
                })
                .split(", ")
                .join(" ")
            }}" {{ systemlog.event_type }} "{{ systemlog.event_message }}"
            {{ systemlog.event_source }}
          </p>
        </div>
      </div>
      <div class="bg-blue-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex">
        <div class="flex my-auto mx-1">
          <span>DEVICE_LOG</span>
          <HashLoader
            :size="20"
            v-show="loadingD"
            class="px-4"
            :color="`#FFFFFF`"
          />
        </div>
        <div class="flex flex-row">
          <div class="flex">
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm items-center"
              v-on:click.prevent="SET_DEVICE_HEIGHT()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                />
              </svg>
            </button>
          </div>
          <div>
            <button
              class="flex font-normal px-2 mr-1 border border-gray-50 my-2 rounded-sm"
              v-on:click.prevent="switchAutoUpdate('DEVICE_LOG')"
            >
              {{
                !enableAutoUpdateDeviceLogs
                  ? "ENABLE:AUTO_UPDATE"
                  : "DISABLE:AUTO_UPDATE"
              }}
            </button>
          </div>
        </div>
      </div>
      <div
        class="flex-col px-0 py-1 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
        v-bind:class="GET_DEVICE_HEIGHT()"
      >
        <div
          v-for="devicelog in DEVICE_LOGS"
          :key="devicelog.id"
          class="text-xs px-1 leading-5"
        >
          <p
            class="border-b border-dashed font-mono"
            :class="devicelog.event_type == 'WARN' ? 'bg-red-300' : ''"
          >
            "{{
              new Date(devicelog.created_at)
                .toLocaleTimeString([], {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit"
                })
                .split(", ")
                .join(" ")
            }}" {{ devicelog.event_type }} "{{ devicelog.event_message }}"
            {{ devicelog.player_sn }} {{ devicelog.event_source }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { HashLoader } from "@saeris/vue-spinners";
export default {
  name: "Eventlog",
  components: { HashLoader },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.UpdateAuthLogs();
    this.UpdateSystemLogs();
    this.UpdateDeviceLogs();
  },
  beforeDestroy() {
    clearInterval(this.AuthLogUpdater);
    clearInterval(this.SystemLogUpdater);
    clearInterval(this.DeviceLogUpdater);
  },
  data() {
    return {
      loadingA: true,
      loadingS: true,
      loadingD: true,
      hasError: false,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      AUTH_LOGS: [],
      SYSTEM_LOGS: [],
      DEVICE_LOGS: [],
      enableAutoUpdateAuthLogs: true,
      enableAutoUpdateSystemLogs: true,
      enableAutoUpdateDeviceLogs: true,
      AuthLogUpdater: null,
      SystemLogUpdater: null,
      DeviceLogUpdater: null,
      windowsSize: {
        auth: "max-h-0",
        system: "max-h-0",
        device: "h-full",
      },
    };
  },
  methods: {
    GET_AUTH_HEIGHT: function () {
      return this.windowsSize.auth;
    },
    GET_SYSTEM_HEIGHT: function () {
      return this.windowsSize.system;
    },
    GET_DEVICE_HEIGHT: function () {
      return this.windowsSize.device;
    },

    SET_AUTH_HEIGHT: function () {
      if (this.windowsSize.auth == "h-full") {
        this.windowsSize = {
          auth: "",
          system: "",
          device: "",
        };
      } else {
        this.windowsSize = {
          auth: "h-full",
          system: "max-h-0",
          device: "max-h-0",
        };
      }
    },
    SET_SYSTEM_HEIGHT: function () {
      if (this.windowsSize.system == "h-full") {
        this.windowsSize = {
          auth: "",
          system: "",
          device: "",
        };
      } else {
        this.windowsSize = {
          auth: "max-h-0",
          system: "h-full",
          device: "max-h-0",
        };
      }
    },
    SET_DEVICE_HEIGHT: function () {
      if (this.windowsSize.device == "h-full") {
        this.windowsSize = {
          auth: "",
          system: "",
          device: "",
        };
      } else {
        this.windowsSize = {
          auth: "max-h-0",
          system: "max-h-0",
          device: "h-full",
        };
      }
    },

    switchAutoUpdate: function (LOG_TYPE) {
      switch (LOG_TYPE) {
        case "AUTH_LOG":
          if (this.enableAutoUpdateAuthLogs) {
            clearInterval(this.AuthLogUpdater);
            this.enableAutoUpdateAuthLogs = false;
          } else {
            this.UpdateAuthLogs();
            this.enableAutoUpdateAuthLogs = true;
          }
          break;
        case "SYSTEM_LOG":
          if (this.enableAutoUpdateSystemLogs) {
            clearInterval(this.SystemLogUpdater);
            this.enableAutoUpdateSystemLogs = false;
          } else {
            this.UpdateSystemLogs();
            this.enableAutoUpdateSystemLogs = true;
          }
          break;
        case "DEVICE_LOG":
          if (this.enableAutoUpdateDeviceLogs) {
            clearInterval(this.DeviceLogUpdater);
            this.enableAutoUpdateDeviceLogs = false;
          } else {
            this.UpdateDeviceLogs();
            this.enableAutoUpdateDeviceLogs = true;
          }
          break;
        default:
          break;
      }
    },
    UpdateAuthLogs: function () {
      let that = this;
      this.GET_AUTH_LOGS();
      this.AuthLogUpdater = setInterval(function () {
        that.GET_AUTH_LOGS();
      }, 10000);
    },
    UpdateSystemLogs: function () {
      let that = this;
      this.GET_SYSTEM_LOGS();
      this.SystemLogUpdater = setInterval(function () {
        that.GET_SYSTEM_LOGS();
      }, 10000);
    },
    UpdateDeviceLogs: function () {
      let that = this;
      this.GET_DEVICE_LOGS();
      this.DeviceLogUpdater = setInterval(function () {
        that.GET_DEVICE_LOGS();
      }, 10000);
    },
    GET_AUTH_LOGS: function () {
      this.loadingA = true;
      this.$store.dispatch("GET_AUTH_LOGS", {}).then(
        (response) => {
          this.loadingA = false;
          this.AUTH_LOGS = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loadingA = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            this.loadingA = false;
            alert("Internal Server Error :(");
          }
        }
      );
    },
    GET_SYSTEM_LOGS: function () {
      this.loadingS = true;
      this.$store.dispatch("GET_SYSTEM_LOGS", {}).then(
        (response) => {
          this.loadingS = false;
          this.SYSTEM_LOGS = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loadingS = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            this.loadingS = false;
            alert("Internal Server Error :(");
          }
        }
      );
    },
    GET_DEVICE_LOGS: function () {
      this.loadingD = true;
      this.$store.dispatch("GET_DEVICE_LOGS", {}).then(
        (response) => {
          this.loadingD = false;
          this.DEVICE_LOGS = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.loadingD = false;
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            this.hasError = true;
            alert("Internal Server Error :(");
          }
        }
      );
    },
  },
};
</script>
